import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Share = (props) => {

  return (
    <>

    <div className="is-inner-page">

    <Layout>

    <SEO title="Início" />

    <div className="section">
      <div className="container">
        <div className="document-container" style={{ backgroundImage: `url(${require('../images/backgrounds/section-dots-bg.svg')})` }}>
          <div className="document-content">
            <h1 className="title">
              Política de Privacidade
            </h1>
            <div className="v-space-lg"></div>
            <p className="text">

              Como parte integrante dos Termos de Uso e Condições de Navegação do Portal ATITUDES DO BEM, este documento, denominado Política de Privacidade, tem por finalidade estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos usuários, além do registro de suas atividades.
              <br/>
              <br/>
              Como condição para acesso e uso dos serviços e funcionalidades, você declara ser maior de 18 (dezoito) anos e que fez a leitura completa e atenta das regras deste documento e dos Termos de Uso, estando plenamente ciente conferindo assim seu livre e expresso consentimento com os termos aqui estipulados. 
              <br/>
              <br/>
              Caso não esteja de acordo com estas diretivas, você deve descontinuar o seu acesso ou uso.
              <br/>
              <br/>
              <br/>
              <br/>
              1. Definições:
              <br/>
              <br/>
              1.1. Para fins desta Política de Privacidade, aplicam-se as seguintes definições:
              <br/>
              <br/>
              Cookies: Arquivos enviados pelo servidor do Portal ATITUDES DO BEM para o computador dos usuários, com a finalidade de identificar o computador e obter dados de acesso, como páginas navegadas ou links clicados, permitindo, desta forma, personalizar a navegação dos usuários de acordo com o seu perfil.
              <br/>
              <br/>
              Dados Anonimizados: Dados considerados sem a identificação de seu proprietário ou vinculação de quem se aponta. Dados sem rosto.
              <br/>
              <br/>
              Dados Pessoais: Dados que dizem respeito à identificação de uma pessoa, compreendendo dados cadastrais e dados sensíveis.
              <br/>
              <br/>
              Dados Sensíveis: Dados que possam discriminar o titular de alguma forma, seja pelo comportamento ou que diz respeito diretamente à personalidade, escolhas, gostos e preferências do indivíduo, a exemplo de convicção religiosa, política, filosófica, moral, da origem racial ou étnica, orientação sexual ou dados médicos, de saúde, genéticos ou biométricos.
              <br/>
              <br/>
              GRUPO MALWEE: Denominação utilizada nesta Política de Privacidade para identificar a detentora e proprietária deste site, MALWEE MALHAS LTDA., pessoa jurídica de direito privado inscrita no CNPJ com o número 84.429.737/0001-14, com sede na cidade de Jaraguá do Sul, Estado de Santa Catarina, à Rua Bertha Weege nº 200, Bairro Barra do Rio Cerro sob o CEP 89260-900 e INSTITUTO MALWEE, pessoa jurídica de direito privado constituída sob a forma de associação civil para fins não econômicos, com sede na Rua Alvorada, 1289, 14º andar, sala 01, CEP 04550-004, Vila Olímpia, cidade e Estado de São Paulo,
              <br/>
              <br/>
              IP: Abreviatura de Internet Protocol. É um conjunto de números que identifica o computador dos usuários na Internet.
              <br/>
              <br/>
              Logs: Registros de atividades dos e usuários efetuadas no Portal ATITUDES DO BEM.
              <br/>
              <br/>
              Portal ATITUDES DO BEM: Designa o site de internet hospedado em www.atitudesdobem.com.br e seus subdomínios.
              <br/>
              <br/>
              Usuários: Pessoas que acessam ou interagem com as atividades oferecidas pelo Portal ATITUDES DO BEM.
              <br/>
              <br/>
              Web beacons: Linhas de programação em páginas HTML que tem como finalidade obter detalhes da navegação do usuário, a exemplo de quanto tempo ficou com um site aberto, qual endereço visitado em seguida, dentre outros.
              <br/>
              <br/>
              <br/>
              <br/>
              2. Obtenção dos Dados Coletados, Forma e Finalidade:
              <br/>
              <br/>
              2.1. Os dados e informações serão obtidos quando os usuários interagirem com as diversas funcionalidades existentes no Portal ATITUDES DO BEM. São exemplos de interação quando fornecem informações voluntariamente dados de cadastro ou inserem dados sobre produtos, serviços e transações realizadas.
              <br/>
              <br/>
              2.1.2 Deste modo, fica desde já o usuário ciente de que ao aceitar esta política de privacidade de dados, da aceite, de maneira expressa e inequívoca, a todas as condições descritas neste documento.
              <br/>
              <br/>
              Os dados pessoais são coletados quando você insere ou submete voluntariamente ao acessar, usufruir e interagir com as funcionalidades ou serviços disponibilizados, pela ATITUDES DO BEM, que inclui:
              <br/>
              <br/>

            </p>
            
            <img src={require('../images/pics/table-01.png')} alt=""/>

            <img src={require('../images/pics/table-02.png')} alt=""/>

            <div className="v-space-lg"></div>

            <p className="text">
              3. Armazenamento dos Dados e Informações:
              <br/>
              <br/>
              3.1. Todos os dados e informações coletados dos usuários serão incorporados ao banco de dados do Portal ATITUDES DO BEM, sendo seu responsável  MALWEE MALHAS LTDA., pessoa jurídica de direito privado inscrita no CNPJ com o número 84.429.737/0001-14, com sede na cidade de Jaraguá do Sul, Estado de Santa Catarina, à Rua Bertha Weege nº 200, Bairro Barra do Rio Cerro sob o CEP 89260-900.
              <br/>
              <br/>
              3.2. Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessadas por pessoas qualificadas e autorizadas pelo GRUPO MALWEE.
              <br/>
              <br/>
              3.3. Considerando que nenhum sistema de segurança é absolutamente seguro, O GRUPO MALWEE se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados do Portal ATITUDES DO BEM, salvo nos casos comprovados de dolo ou culpa.
              <br/>
              <br/>
              3.4. Você pode solicitar que os dados fornecidos sejam atualizados, pedir esclarecimentos sobre a forma de seu armazenamento e utilização ou revogar a qualquer tempo o consentimento para seu uso, através de manifestação por escrito ao GRUPO MALWEE. 
              <br/>
              <br/>
              3.5. Nosso encarregado da proteção de dados pode ser contatado a partir do endereço: sac@malwee.com.br.
              <br/>
              <br/>
              <br/>
              <br/>
              4. Uso dos Dados e Informações:
              <br/>
              <br/>
              4.1. Os dados e informações coletados dos usuários poderão ser utilizados para as seguintes finalidades:
              <br/>
              Responder a eventuais dúvidas e solicitações dos usuários;
              <br/>
              Cumprimento de ordem legal ou judicial;
              <br/>
              Realizar operações em nome do usuário a partir de comandos executados na plataforma ATITUDES DO BEM, incluindo a divulgação a terceiro sempre que indispensável, por exemplo, emitir uma Nota fiscal com seu certificado digital;
              <br/>
              Para garantir a sua segurança no uso do portal ATITUDES DO BEM e para segurança de todos os usuários;
              <br/>
              Constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;
              <br/>
              Elaborar estatísticas gerais, para identificação do perfil dos usuários e desenvolvimento de campanhas do GRUPO MALWEE, mas sem realizar identificação pessoal.;
              <br/>
              Determinação de tendências de comércio e necessidades de fornecedores e consumidores por produtos ou serviços;
              <br/>
              Manter atualizados os cadastros dos usuários para fins de contato por telefone, correio eletrônico, SMS, mala direta ou por outros meios de comunicação;
              <br/>
              Informar a respeito de novidades, promoções e eventos do GRUPO MALWEE;
              <br/>
              Aumentar a visibilidade das campanhas de arrecadação de fundos e projetos ativos no Portal ATITUDES DO BEM;
              <br/>
              Proporcionar melhorias no conteúdo e serviços prestados.
              <br/>
              <br/>
              4.2. A base de dados formada pelo GRUPO MALWEE poderá ser compartilhada com parceiros e fornecedores comerciais do GRUPO MALWEE.
              <br/>
              <br/>
              4.2.1. Em casos específicos, algumas informações anonimizadas poderão ser compartilhadas com parceiros comerciais, em especial, nas ações de marketing com participação relevante do GRUPO MALWEE.
              <br/>
              <br/>
              4.2.2. Sempre que necessário para a total execução de serviços contratados, o GRUPO MALWEE poderá fornecer informações anonimizadas ou dados de seus usuários aos contratados correspondentes, tomando as medidas legais e técnicas de modo a tornar o fornecimento de dados ou informações tão seguro quanto possível.
              <br/>
              <br/>
              4.2.3. O GRUPO MALWEE não é responsável pelo mau uso que parceiros comerciais ou contratados fizerem das informações fornecidas, pois está fora de sua esfera de vigilância e controle.
              <br/>
              <br/>
              4.3. Dados adquiridos somente poderão ser acessados por profissionais devidamente autorizados pelo GRUPO MALWEE, respeitando a necessidade a que serão submetidos, a relevância para os objetivos do Portal ATITUDES DO BEM e aos interesses dos usuários, além de preservar a privacidade dos mesmos.
              <br/>
              <br/>
              4.4. Caso o usuário deixe de utilizar os serviços oferecidos pelo Portal ATITUDES DO BEM, O GRUPO MALWEE poderá, para fins de auditoria e preservação de direitos, permanecer com o registro de dados e informações do usuário, pelo período máximo de 5 (cinco) anos, com a faculdade de excluí-los definitivamente segundo sua conveniência.
              <br/>
              <br/>
              4.4.1 – O GRUPO MALWEE se compromete a não promover nenhuma alteração nos dados ora coletados.
              <br/>
              <br/>
              4.5. O usuário poderá exigir do GRUPO MALWEE os dados que estão registrados que lhe dizem respeito, da mesma forma que poderá solicitar a alteração ou exclusão dos mesmos. Para isso, deve entrar em contato no email mencionado no item 3.5..
              <br/>
              <br/>
              4.6. O GRUPO MALWEE somente divulgará as informações ou dados pessoais, caso exista consentimento expresso do usuário, quando houver determinação legal ou judicial.
              <br/>
              <br/>
              <br/>
              <br/>
              5. Registro de Atividades:
              <br/>
              <br/>
              5.1. O GRUPO MALWEE registrará todas as atividades efetuadas pelos usuários no Portal ATITUDES DO BEM, por meio de logs, incluindo:
              <br/>
              IP dos usuários;
              <br/>
              Ações realizadas pelos usuários no Portal ATITUDES DO BEM;
              <br/>
              Páginas acessadas;
              <br/>
              Datas e horários de toda as ações e acesso a cada página do Portal ATITUDES DO BEM;
              <br/>
              Informações sobre o dispositivo utilizado, versão de sistema operacional, navegador, dentre outros aplicativos instalados;
              <br/>
              Session ID (quando disponível).
              <br/>
              <br/>
              5.2. Os registros mencionados no item 5.1 poderão ser utilizados pelo GRUPO MALWEE em casos de investigação de fraudes ou de alterações indevidas em seus sistemas e cadastros.
              <br/>
              <br/>
              <br/>
              <br/>
              6. Cookies e Outras Tecnologias de Obtenção de Dados:
              <br/>
              <br/>
              6.1. O Portal ATITUDES DO BEM poderá fazer o uso de cookies, cabendo aos usuários configurarem o seu navegador de Internet, caso deseje bloqueá-los. Nesta hipótese, algumas funcionalidades do Portal ATITUDES DO BEM poderão ser limitadas.
              <br/>
              <br/>
              6.2. O Portal também poderá utilizar web beacons para coletar dados de comportamento dos usuários das páginas, onde a instalação de arquivos nos equipamentos não é necessária.
              <br/>
              <br/>
              6.3. Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo usuário, no entanto, os termos desta política e as opções do usuário a respeito de sua coleta e armazenamento serão respeitados.
              <br/>
              <br/>
              <br/>
              <br/>
              7. Disposições Gerais:
              <br/>
              <br/>
              7.1. As disposições constantes desta Política de Privacidade poderão ser atualizadas ou modificadas a qualquer momento, cabendo aos usuários verificá-la sempre que acessar o Portal ATITUDES DO BEM. Navegar ou interagir com o Portal ATITUDES DO BEM após atualizações/modificações será interpretado como uma concordância tácita às novas regras aplicadas.
              <br/>
              <br/>
              7.2. Caso haja alguma dúvida sobre a nossa Política de Privacidade, por favor, entre em contato através e-mail descrito no item 3.5. Será um prazer ajudar te ajudar!
              <br/>
              <br/>
              <br/>
              <br/>
              8. Lei Aplicável e Jurisdição
              <br/>
              <br/>
              8.1. A presente Política de Privacidade será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de Jaraguá do Sul, Estado de Santa Catarina, para dirimir qualquer litígio, questão ou dúvida superveniente, com expressa renúncia de qualquer outro, por mais privilegiado que seja.

            </p>

          </div>
        </div>
      </div>
    </div>



    </Layout>

    </div>
     </>
  );

};

export default Share;
